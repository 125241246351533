
import { Component, Emit, Vue } from "vue-property-decorator";
import store from "@/store/index";
import Utilities from "@/utils/Utilities";
import LogUtils from "@/utils/LogUtils";
import { IHomeViewTile } from "./IHomeViewTile";
import { IUserTile } from "./IUserTile";

@Component({
    components: {},
})
export default class TheGettingStarted extends Vue {
    // unreadMessageCount: Number | undefined = 0;
    loading = true;

    zoomIntegrationEnabled = true;

    dismissedTiles: string[] = [];

    get tiles(): IHomeViewTile[] {
        return [
            // {
            //     id: "learn_about_programmes",
            //     title: "Learn about Programmes",
            //     userRoles: ["Trainer", "TenantAdmin"],
            //     content:
            //         "Understanding how to use programmes is fundamental to getting the most out of GiraffePad.<br><br> We've made a short video to explain what programmes are all about.",
            //     buttonText: "Watch the video",
            //     buttonAction: this.startTour,
            //     buttonIcon: "play-btn",
            //     actionArgs: {
            //         tourId: "ykzwtd887PA",
            //     },
            //     dimissable: true,
            //     isVisible: () => (this as any).$mq !== "mobile",
            // },
            // {
            //     id: "create_a_programme",
            //     title: "Create a Programme",
            //     userRoles: ["Trainer", "TenantAdmin"],
            //     content: "Ready to create your own programme? This tour will show you how.",
            //     buttonText: "Let's do it!",
            //     buttonAction: this.startTour,
            //     buttonIcon: null,
            //     actionArgs: {
            //         tourId: "8YDcyMDjH8p",
            //     },
            //     dimissable: false,
            //     isVisible: () =>
            //         !Utilities.userTenantHasProgramme(store.state.user) &&
            //         (this as any).$mq !== "mobile",
            // },
            // {
            //     id: "take_a_ui_tour",
            //     title: "Take a UI Tour",
            //     userRoles: ["Trainer", "TenantAdmin"],
            //     content: "Let us show you around so you know where to find everything!",
            //     buttonText: "Start tour",
            //     buttonAction: this.startTour,
            //     buttonIcon: null,
            //     actionArgs: {
            //         tourId: "y7JGCfIPQ9m",
            //     },
            //     dimissable: true,
            //     isVisible: () => (this as any).$mq !== "mobile",
            // },
            // {
            //     id: "complete_your_profile",
            //     title: "Complete Your Profile",
            //     userRoles: ["Trainer", "TenantAdmin"],
            //     content:
            //         "Completing your profile is important - it helps learners and other team members engage and make a connection with you.",
            //     buttonText: "Show me!",
            //     buttonAction: this.startTour,
            //     buttonIcon: null,
            //     actionArgs: {
            //         tourId: "DBecQrTL4wq",
            //     },
            //     dimissable: false,
            //     isVisible: () => !Utilities.userProfileComplete(store.state.user),
            // },
            // {
            //     id: "enable_zoom_integration",
            //     title: "Enable Zoom Integration",
            //     userRoles: ["TenantAdmin"],
            //     content:
            //         "Connecting to a Zoom account enables you to easily schedule Zoom meetings in your GiraffePad programmes! ",
            //     buttonText: "Let's do it!",
            //     buttonAction: this.redirect,
            //     buttonIcon: null,
            //     actionArgs: {
            //         route: {
            //             name: "Integrations",
            //         },
            //     },
            //     dimissable: true,
            //     isVisible: () => this.zoomEnabled === false,
            // },
            // {
            //     id: "learn_about_questionnaires",
            //     title: "Learn about Questionnaires",
            //     userRoles: ["Trainer", "TenantAdmin"],
            //     content:
            //         "The questionnaires feature can be used for all levels of feedback, quizzes, knowledge tests, needs analysis and more.<br><br> Watch the video to see how you can use questionnaires to build even more incredible learning journeys in GiraffePad.",
            //     buttonText: "Watch the video",
            //     buttonAction: this.startTour,
            //     buttonIcon: "play-btn",
            //     actionArgs: {
            //         tourId: "oNm1KHyjfBm",
            //     },
            //     dimissable: true,
            //     isVisible: () => true,
            // },
            {
                id: "tour_1_getting_started",
                title: "1: Getting Started",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 1 we focus on the 6 key tasks to get your account up and running.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "lJaEOvMMYs",
                    image: "thumbnails/1.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_2_programmes",
                title: "2: Programmes",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 2 we focus on Programmes which are at the very heart of everything you will do in your GiraffePad account.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "RndPHmwdEk",
                    image: "thumbnails/2.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_3_content",
                title: "3: Content",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 3 we focus on your learning content and resources, how you can upload, curate and share these with your learners.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "NZC0Y9UDyl",
                    image: "thumbnails/3.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_4_events",
                title: "4: Events",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 4 we focus on the live events you can add to your Learning Journey, virtual or in-person. Use these to help your learners arrive fully prepped and ready to learn!",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "uAvJ0hVkcv",
                    image: "thumbnails/4.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_5_activities",
                title: "5: Activities",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 5 we focus on the activities you can add to your Learning Journey, for everything you want your learners to do whilst you are not there!",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "jggx1zzche",
                    image: "thumbnails/5.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_6_questionnaires",
                title: "6: Questionnaires",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 6 we focus on the questionnaires you can add to your Learning Journey, fantastic for needs analysis, knowledge tests, feedback, reflective learning and more!",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "us9QSBYYk9",
                    image: "thumbnails/6.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_7_inviting_learners",
                title: "7: Inviting Learners",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 7 we take a look at the flexible ways you can invite learners into your Programmes.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "ILCT6oA8x4",
                    image: "thumbnails/7.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_8_chat_and_community",
                title: "8: Chat and Community",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 8 we take a look at the chat functionality that you have to create a sense of community amongst your learners and also for private conversations.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "UgKREPRonS",
                    image: "thumbnails/8.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_9_learner_engagement",
                title: "9: Learner Engagement",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 9 we take a look at the tracking tools you have for learner engagement.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "rJRR0X1Byt",
                    image: "thumbnails/9.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
            {
                id: "tour_10_cloning",
                title: "10: Cloning",
                userRoles: ["Trainer", "TenantAdmin"],
                content:
                    "In episode 10 we take a look at the cloning functionality that saves you oodles of time copying individual items or whole programmes.",
                buttonText: "Watch the video",
                buttonAction: this.startTour,
                buttonIcon: "play-btn",
                actionArgs: {
                    tourId: "VztmCk1dku",
                    image: "thumbnails/10.jpg",
                },
                dimissable: true,
                isVisible: () => true,
            },
        ];
    }

    get zoomEnabled(): boolean {
        return this.zoomIntegrationEnabled;
    }

    get userTiles(): IHomeViewTile[] {
        // console.log("userTiles()");
        if (this.loading) {
            return [];
        }

        // console.log(this.zoomEnabled);
        // this.tiles.forEach((t) => {
        //     console.log(`${t.id} is visible: `, t.isVisible());
        // });

        const userTiles = this.tiles.filter(
            (t) =>
                Utilities.userIsInAnyOfRoles(store.state.user, t.userRoles) &&
                !this.dismissedTiles.includes(t.id) &&
                t.isVisible()
        );

        return userTiles;
    }

    get isVisible(): boolean {
        return !this.loading && this.userTiles.length > 0;
    }

    startTour(tile: IHomeViewTile) {
        globalThis.helpHero.startTour(tile.actionArgs.tourId);
    }

    redirect(tile: IHomeViewTile) {
        this.$router.push(tile.actionArgs.route);
    }

    confirmHideTile(tile: IHomeViewTile) {
        Utilities.gpModalConfirm(
            this.$bvModal,
            "Hide this?",
            "Please confirm that you don't wish to see this any more.",
            "Hide",
            "Cancel",
            true
        ).then((confirmed) => {
            if (confirmed) {
                Vue.prototype.$http
                    .delete(`/v1/users/me/tiles/${tile.id}`)
                    .then(() => {
                        this.dismissedTiles.push(tile.id);
                    })
                    .catch((err) => {
                        LogUtils.Error("Unable to hide tile", err, "TheGettingStarted");
                    });
            }
        });
    }

    created() {
        // Get the ids of tiles already dsimissed by this user
        Vue.prototype.$http
            .get("/v1/users/me/tiles")
            .then((res) => {
                this.dismissedTiles = res.data
                    .filter((t: IUserTile) => t.hidden)
                    .map((t: IUserTile) => t.tileId);
                this.loading = false;
                this.loaded();
            })
            .catch((err) => {
                LogUtils.Error("Unable to get user's hidden tiles", err, "TheGettingStarted");
                this.loading = false;
                this.loaded();
            });

        Vue.prototype.$http
            .get(`/v1/accounts/${Utilities.userTenantId(store.state.user)}/integrations/zoom`)
            .then((res) => {
                this.zoomIntegrationEnabled = res.data.integrationEnabled;
            })
            .catch(() => {
                this.zoomIntegrationEnabled = false;
            });
    }

    @Emit() loaded() {
        console.log("TheGettingStarted loaded");
    }
}
